.Card {
  padding: 1rem 1rem;
  border-radius: 8px;
  height: fit-content;
  transition: all ease-in-out 150ms;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: 5px 5px 5px grey;
}

#default {
  background: white;
}

#nAfter {
  background: rgb(114,187,15);
  background: linear-gradient(140deg, rgba(114,187,15,1) 0%, rgba(0,212,255,0) 60%);
}

#iAfter {
  background: rgb(254,72,128);
  background: linear-gradient(140deg, rgba(254,72,128,1) 0%, rgba(0,212,255,0) 60%);
}

.Card:hover {
  box-shadow: 1px 1px 1px grey;
}

.selected {
  cursor: default;
  box-shadow: 0 0 0 3px rgba(6, 123, 249, 0.7);
}

.selected:hover {
  cursor: default;
  box-shadow: 0 0 0 3px rgba(6, 123, 249, 0.7);
}

.Card .container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.Card .container .info {
  flex: 1 1 auto;
}

.info .task {
  font-size: 20px;
}

.info .time {
  font-size: 16px;
}

.info .task-input {
  width: 90%;
  font-size: 22px;
  font-weight: bold;
  color: #0c68ca;
  background-color: #f3f3f3;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 0 2px transparent;
  transition: all ease-in-out 200ms;
}

.info .task-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(6, 123, 249, 0.4);
}

.info .time-input {
  font-size: 18px;
  color: #5A5A5A;
  margin-top: 5px;
  border: none;
  border-bottom: solid transparent 1px;
  transition: all ease-in-out 200ms;
}

.info .time-input:focus {
  outline: none;
}

.btn-group button {
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 0 5px;
  font-size: 18px;
  transition: all ease-in-out 100ms;
}

.btn-group #edit:hover {
  background-color: #6C757C;
  color: white;
}

.btn-group #done:hover {
  background-color: #2FA74E;
  color: white;
}

.btn-group #del:hover {
  background-color: #DA3648;
  color: white;
}

.btn-group button:focus {
  cursor: pointer;
  outline: none;
}