input,button{
    appearance: none;
    background: none;
    outline: none;
}

form{
    display: flex;
    position: relative;
    z-index: 0;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    border-radius: 8px;
}

form .form-inner{
    border-radius: 30px;
    box-shadow: 5px 5px 5px grey;
    /* position: relative; */
    /* display: block; */
    background-color: #FFF;
    padding: 30px 40px 30px 30px;
    /* z-index: 2; */
}

form .form-inner h2{
    color: #888;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}

form .form-inner .form-group{
    display: block;
    width: 300px;
    margin-bottom: 15px;
}

.form-inner .form-group label{
    display: block;
    color: #666;
    font-size: 18px;
    margin-bottom: 5px;
    transition: 0.4s;
}

.form-inner .form-group:focus-within label{
    color: #FE4880;
}

form .form-inner .form-group input{
    display: block;
    width: 100%;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 8px;
    transition: 0.5s;
}

form .form-inner .form-group input:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

form .form-inner input[type="submit"]{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: none;
}

form .form-inner input[type="submit"]:hover{
    background-position: 100% 0%;
}

.error {
    color: #FE4880;
    text-align: center;
}

.span{
    color: #FE4880;
    cursor: pointer;
}

p {
    font-size: 15px;
    padding: 5px;
}

.errorMsg {
    color: #FF4880;
    text-align: center;
}

.form-group {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .fas .tooltiptext {
    font-size: 16px;
    visibility: hidden;
    width: 50%;
    background-color: grey;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px 10px;
    margin: 10px 10px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
  }
  
  .fas .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -20px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent grey transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .fas:hover .tooltiptext {
    visibility: visible;
  }
  