.ContactPage .card-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.stat-container {
    background-color: White;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.stat-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.stat-container h1 {
    font-size: 40px;
}

.ContactPage .profile-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.ContactPage .card-container .pic img {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
}

.ContactPage .card-container .pic img:hover {
    transform: scale(1.2);
}

.homePage .pic img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
    margin-right: 50px;
    box-shadow: 3px 3px 3px grey;
}

.homePage .pic img:hover {
    transform: scale(1.2);
}

.homePage .informationBox {
    display: flex;
    margin-top: 80px;
}

.ContactPage .profile-container .pic img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
}

.ContactPage .card-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.ContactPage .gif-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.AuthPage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
    text-align: center;
}

.TodoPage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
}

.CreatePage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
}

.ContactPage .gif-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 2rem;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.item-m {
    padding: 5px;
    width: 50px;
    transition: 0.5s;
}

.item-s {
    padding: 5px;
    width: 45px;
    transition: 0.5s;
}

.item-l {
    padding: 5px;
    width: 55px;
    transition: 0.5s;
}

.item-m:hover {
    transform: scale(1.3);
}

.item-s:hover {
    transform: scale(1.3);
}

.item-l:hover {
    transform: scale(1.3);
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.auth-loading {
    position: fixed;
    top: 75%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.button {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #ffa622, #ff6600 50%, #FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.button:hover {
    background-position: 100% 0%;
}

#head {
    display: flex;
}

.TodoPage .SearchBox {
    margin-left: 50px;
    color: #FF4880;
}

.searchComponent {
    margin-left: 20px;
    margin-top: 10px;
}

.searchComponent .search-container input[type=text] {
    width: 30px;
    margin-right:10px;
    box-shadow: 5px 5px 5px grey;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 50px;
    border: none;
    transition: 0.5s;
}

/* When the input field gets focus, change its width to 100% */

.searchComponent .search-container input[type=text]:focus {
    width: 300px;
    border-radius: 10px;
    content: 'Search';
}

.searchComponent .search-container button{
    display: none;
}
