@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;700&display=swap');

@font-face {
  font-family: "Product Sans";
  font-weight: 400;
  src: url(./assets/ProductSans-Regular.ttf);
}

@font-face {
  font-family: "Product Sans";
  font-weight: bold;
  src: url(./assets/ProductSans-Bold.ttf);
}

@font-face {
  font-family: "Product Sans";
  font-weight: lighter;
  src: url(./assets/ProductSans-Light.ttf);
}

.App {
  width: 40vw;
  min-width: 500px;
  margin: 0 auto;
}

body {
  margin-top: 60px;
  background-color: #E5E5E5;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  font-family: "Product Sans", "Kanit", FontAwesome, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

.loading{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

}

