.Profile {
    padding: 1rem 1rem;
    border-radius: 8px;
    background-color: white;
    height: fit-content;
    transition: all ease-in-out 150ms;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }
  
  
  .Profile .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .Profile .container .info {
    flex: 1 1 auto;
  }
  
  .info .task {
    font-size: 20px;
  }
  
  .info .time {
    font-size: 16px;
    color: #5A5A5A;
  }
  
  .info .task-input {
    width: 90%;
    font-size: 22px;
    font-weight: bold;
    color: #0c68ca;
    background-color: #f3f3f3;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 0 2px transparent;
    transition: all ease-in-out 200ms;
  }
  
  .info .task-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(6, 123, 249, 0.4);
  }
  
  .info .time-input {
    font-size: 18px;
    color: #5A5A5A;
    margin-top: 5px;
    border: none;
    border-bottom: solid transparent 1px;
    transition: all ease-in-out 200ms;
  }
  
  .info .time-input:focus {
    outline: none;
  }
  
  .btn-group button {
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 0 5px;
    font-size: 18px;
    transition: all ease-in-out 100ms;
  }
  
  .btn-group #edit:hover {
    background-color: #6C757C;
    color: white;
  }
  
  .btn-group #done:hover {
    background-color: #2FA74E;
    color: white;
  }
  
  .btn-group #del:hover {
    background-color: #DA3648;
    color: white;
  }
  
  .btn-group button:focus {
    cursor: pointer;
    outline: none;
  }
  

  form .form-inner .form-group{
    display: block;
    width: 300px;
    margin-bottom: 15px;
}

.form-inner .form-group label{
    display: block;
    color: #666;
    font-size: 18px;
    margin-bottom: 5px;
    transition: 0.4s;
}

.form-inner .form-group:focus-within label{
    color: #FE4880;
}

form .form-inner .form-group input{
    display: block;
    width: 100%;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 8px;
    transition: 0.5s;
}

form .form-inner .form-group input:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

form .form-inner input[type="submit"]{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    
}

form .form-inner input[type="button"]{
  display: inline-block;
  padding: 10px 15px;
  margin-left: 5%;
  border-radius: 8px;
  background-image: linear-gradient(to right,#ffa622,#ff6600 50%,#FF4880);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

form .form-inner input[type="submit"]:hover{
    background-position: 100% 0%;
}

form .form-inner input[type="button"]:hover{
  background-position: 100% 0%;
}

input[type="submit"]{
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border: none;
}

input[type="submit"]:hover{
  background-position: 100% 0%;
}