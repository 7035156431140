@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Product Sans";
  font-weight: 400;
  src: url(/static/media/ProductSans-Regular.2765c696.ttf);
}

@font-face {
  font-family: "Product Sans";
  font-weight: bold;
  src: url(/static/media/ProductSans-Bold.153c4f86.ttf);
}

@font-face {
  font-family: "Product Sans";
  font-weight: lighter;
  src: url(/static/media/ProductSans-Light.1dde435c.ttf);
}

.App {
  width: 40vw;
  min-width: 500px;
  margin: 0 auto;
}

body {
  margin-top: 60px;
  background-color: #E5E5E5;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  font-family: "Product Sans", "Kanit", FontAwesome, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

.loading{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

}


.Card {
  padding: 1rem 1rem;
  border-radius: 8px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all ease-in-out 150ms;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: 5px 5px 5px grey;
}

#default {
  background: white;
}

#nAfter {
  background: rgb(114,187,15);
  background: linear-gradient(140deg, rgba(114,187,15,1) 0%, rgba(0,212,255,0) 60%);
}

#iAfter {
  background: rgb(254,72,128);
  background: linear-gradient(140deg, rgba(254,72,128,1) 0%, rgba(0,212,255,0) 60%);
}

.Card:hover {
  box-shadow: 1px 1px 1px grey;
}

.selected {
  cursor: default;
  box-shadow: 0 0 0 3px rgba(6, 123, 249, 0.7);
}

.selected:hover {
  cursor: default;
  box-shadow: 0 0 0 3px rgba(6, 123, 249, 0.7);
}

.Card .container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.Card .container .info {
  flex: 1 1 auto;
}

.info .task {
  font-size: 20px;
}

.info .time {
  font-size: 16px;
}

.info .task-input {
  width: 90%;
  font-size: 22px;
  font-weight: bold;
  color: #0c68ca;
  background-color: #f3f3f3;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 0 2px transparent;
  transition: all ease-in-out 200ms;
}

.info .task-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(6, 123, 249, 0.4);
}

.info .time-input {
  font-size: 18px;
  color: #5A5A5A;
  margin-top: 5px;
  border: none;
  border-bottom: solid transparent 1px;
  transition: all ease-in-out 200ms;
}

.info .time-input:focus {
  outline: none;
}

.btn-group button {
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 0 5px;
  font-size: 18px;
  transition: all ease-in-out 100ms;
}

.btn-group #edit:hover {
  background-color: #6C757C;
  color: white;
}

.btn-group #done:hover {
  background-color: #2FA74E;
  color: white;
}

.btn-group #del:hover {
  background-color: #DA3648;
  color: white;
}

.btn-group button:focus {
  cursor: pointer;
  outline: none;
}
.navbar {
  width: 100%;
  height: 50px;
  position: fixed;
  background-color: #272727;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
}

.navbar .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
  min-width: 500px;
}

.navbar .content .brand {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.navbar .content .link-page {
  display: flex;
  flex-direction: row;
}

.navbar .content .link-page a {
  margin: 0 1rem;
  color: white;
  font-size: 14px;
  text-decoration: none;
  transition: all ease-in-out 100ms;
}

.navbar .content .link-page a:hover {
  color: #009BFF;
}

.navbar .content .create-button {
  font-size: 16px;
  height: 30px;
  background-color: #067bf9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  transition: all ease-in-out 150ms;
  padding: 0 1rem;
  font-weight: bold;
  margin: 0.3rem;
}

.navbar .content .right-box {
  display: flex;
  flex-direction: row;
}

.navbar .content .create-button:hover {
  background-color: #459dfc;
}

.navbar .content .create-button:focus {
  outline: none;
}

@media screen and (max-width: 1080px) {
  .navbar .content .link-page a {
    margin: 0 0.5rem;
  }
}
.ContactPage .card-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.stat-container {
    background-color: White;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.stat-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.stat-container h1 {
    font-size: 40px;
}

.ContactPage .profile-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.ContactPage .card-container .pic img {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
}

.ContactPage .card-container .pic img:hover {
    transform: scale(1.2);
}

.homePage .pic img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
    margin-right: 50px;
    box-shadow: 3px 3px 3px grey;
}

.homePage .pic img:hover {
    transform: scale(1.2);
}

.homePage .informationBox {
    display: flex;
    margin-top: 80px;
}

.ContactPage .profile-container .pic img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #eaeaea;
    object-fit: cover;
    transition: 0.5s;
}

.ContactPage .card-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.ContactPage .gif-container:hover {
    box-shadow: 1px 1px 1px grey;
}

.AuthPage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
    text-align: center;
}

.TodoPage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
}

.CreatePage h1 {
    font-Size: 48px;
    margin: 0.5rem 0;
}

.ContactPage .gif-container {
    background-color: White;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 2rem;
    box-shadow: 5px 5px 5px grey;
    transition: 0.5s;
}

.item-m {
    padding: 5px;
    width: 50px;
    transition: 0.5s;
}

.item-s {
    padding: 5px;
    width: 45px;
    transition: 0.5s;
}

.item-l {
    padding: 5px;
    width: 55px;
    transition: 0.5s;
}

.item-m:hover {
    transform: scale(1.3);
}

.item-s:hover {
    transform: scale(1.3);
}

.item-l:hover {
    transform: scale(1.3);
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.auth-loading {
    position: fixed;
    top: 75%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.button {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #ffa622, #ff6600 50%, #FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.button:hover {
    background-position: 100% 0%;
}

#head {
    display: flex;
}

.TodoPage .SearchBox {
    margin-left: 50px;
    color: #FF4880;
}

.searchComponent {
    margin-left: 20px;
    margin-top: 10px;
}

.searchComponent .search-container input[type=text] {
    width: 30px;
    margin-right:10px;
    box-shadow: 5px 5px 5px grey;
    transition: width 0.4s ease-in-out;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 50px;
    border: none;
    transition: 0.5s;
}

/* When the input field gets focus, change its width to 100% */

.searchComponent .search-container input[type=text]:focus {
    width: 300px;
    border-radius: 10px;
    content: 'Search';
}

.searchComponent .search-container button{
    display: none;
}

input,button{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: none;
    outline: none;
}

form{
    display: flex;
    position: relative;
    z-index: 0;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    border-radius: 8px;
}

form .form-inner{
    border-radius: 30px;
    box-shadow: 5px 5px 5px grey;
    /* position: relative; */
    /* display: block; */
    background-color: #FFF;
    padding: 30px 40px 30px 30px;
    /* z-index: 2; */
}

form .form-inner h2{
    color: #888;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}

form .form-inner .form-group{
    display: block;
    width: 300px;
    margin-bottom: 15px;
}

.form-inner .form-group label{
    display: block;
    color: #666;
    font-size: 18px;
    margin-bottom: 5px;
    transition: 0.4s;
}

.form-inner .form-group:focus-within label{
    color: #FE4880;
}

form .form-inner .form-group input{
    display: block;
    width: 100%;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 8px;
    transition: 0.5s;
}

form .form-inner .form-group input:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

form .form-inner input[type="submit"]{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: none;
}

form .form-inner input[type="submit"]:hover{
    background-position: 100% 0%;
}

.error {
    color: #FE4880;
    text-align: center;
}

.span{
    color: #FE4880;
    cursor: pointer;
}

p {
    font-size: 15px;
    padding: 5px;
}

.errorMsg {
    color: #FF4880;
    text-align: center;
}

.form-group {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .fas .tooltiptext {
    font-size: 16px;
    visibility: hidden;
    width: 50%;
    background-color: grey;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px 10px;
    margin: 10px 10px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
  }
  
  .fas .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -20px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent grey transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .fas:hover .tooltiptext {
    visibility: visible;
  }
  
.Profile {
    padding: 1rem 1rem;
    border-radius: 8px;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all ease-in-out 150ms;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }
  
  
  .Profile .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .Profile .container .info {
    flex: 1 1 auto;
  }
  
  .info .task {
    font-size: 20px;
  }
  
  .info .time {
    font-size: 16px;
    color: #5A5A5A;
  }
  
  .info .task-input {
    width: 90%;
    font-size: 22px;
    font-weight: bold;
    color: #0c68ca;
    background-color: #f3f3f3;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 0 2px transparent;
    transition: all ease-in-out 200ms;
  }
  
  .info .task-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(6, 123, 249, 0.4);
  }
  
  .info .time-input {
    font-size: 18px;
    color: #5A5A5A;
    margin-top: 5px;
    border: none;
    border-bottom: solid transparent 1px;
    transition: all ease-in-out 200ms;
  }
  
  .info .time-input:focus {
    outline: none;
  }
  
  .btn-group button {
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 0 5px;
    font-size: 18px;
    transition: all ease-in-out 100ms;
  }
  
  .btn-group #edit:hover {
    background-color: #6C757C;
    color: white;
  }
  
  .btn-group #done:hover {
    background-color: #2FA74E;
    color: white;
  }
  
  .btn-group #del:hover {
    background-color: #DA3648;
    color: white;
  }
  
  .btn-group button:focus {
    cursor: pointer;
    outline: none;
  }
  

  form .form-inner .form-group{
    display: block;
    width: 300px;
    margin-bottom: 15px;
}

.form-inner .form-group label{
    display: block;
    color: #666;
    font-size: 18px;
    margin-bottom: 5px;
    transition: 0.4s;
}

.form-inner .form-group:focus-within label{
    color: #FE4880;
}

form .form-inner .form-group input{
    display: block;
    width: 100%;
    padding: 10px 5px;
    background-color: #F8F8F8;
    border-radius: 8px;
    transition: 0.5s;
}

form .form-inner .form-group input:focus{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

form .form-inner input[type="submit"]{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    
}

form .form-inner input[type="button"]{
  display: inline-block;
  padding: 10px 15px;
  margin-left: 5%;
  border-radius: 8px;
  background-image: linear-gradient(to right,#ffa622,#ff6600 50%,#FF4880);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

form .form-inner input[type="submit"]:hover{
    background-position: 100% 0%;
}

form .form-inner input[type="button"]:hover{
  background-position: 100% 0%;
}

input[type="submit"]{
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right,#FFCE00,#FFCE00 50%,#FF4880);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border: none;
}

input[type="submit"]:hover{
  background-position: 100% 0%;
}
